import { scrollbar } from '@engined/client/styles/mixins.js';
import { createTheme } from '@mui/material';

type fixme = any;

export const yellow = '#f8ac59';
export const red = '#ED5565';
export const cyan = '#17a2b8';
export const blue = '#1c84c6';
export const green = '#96d177';
export const purple = '#6f42c1';

// A custom theme for this app
const theme = createTheme({
  palette: {
    text: {
      primary: '#676a6c',
    },
    background: {
      default: '#f3f3f4',
    },
    primary: {
      main: '#ff660d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
    neutral: {
      main: '#D1DADE',
      contrastText: '#5E5E5E',
    },
    danger: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
  },
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...scrollbar,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
        component: 'div',
      } as fixme,
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
  typography: {
    fontFamily: ['"open sans"', '"Helvetica Neue"', 'Helvetica', 'Roboto', 'Arial', 'sans-serif'].join(','),
    fontSize: 12,
    body1: {
      fontSize: '0.8rem', // 12.8px for default 16px HTML
    },
  },
});

export default theme;
